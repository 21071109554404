<template>
  <div class="tranAddMain">
    <el-row class="vd_edhea vg_button_group tran_header">
      <el-col>
        <editHeader :btn="btn" :isShow="isShow" :strForm="tranForm" @closeEdit="closeEdit" @openEdit="openEdit" @submit="submit('tranForm')" />
        <div class="vd_export" v-if="isShow">
          <el-button type="primary" size="small" @click="buttonRefresh()">刷新</el-button>
        </div>
        <div class="vd_export2 vg_mr_8">
          <span style="margin-right: 2px">发票号:</span>
          <el-input disabled size="mini" style="width: 90px" v-model="tranForm.tran_no1" show-word-limit> </el-input>
          <el-input size="mini" style="width: 90px" v-model="tranForm.tran_no2" show-word-limit maxlength="4"> </el-input>
          <el-input size="mini" style="width: 60px" v-model="tranForm.tran_no3" show-word-limit maxlength="1"> </el-input>
        </div>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <el-form :model="tranForm" ref="tranForm" label-width="120px" size="mini" :rules="tranFormRules" :disabled="isShow">
          <el-row>
            <el-col :md="8">
              <el-form-item label="客户简称" prop="cust_abbr">
                <el-input disabled v-model="tranForm.cust_abbr" placeholder="请输入客户简称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="公司抬头" prop="cptt_aid">
                <el-select size="small" filterable v-model="tranForm.cptt_aid" placeholder="请选择公司抬头">
                  <el-option v-for="item in cpttList" :key="item.cptt_id" :label="item.cptt_name" :value="item.cptt_id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="出运经办人" prop="tran_stff_id">
                <el-select v-model="tranForm.tran_stff_id" placeholder="请选择出运经办人">
                  <el-option v-for="item in stffOptionList" :key="item.stff_id" :value="item.stff_id" :label="item.stff_name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="销售合同号" prop="scon_nos">
                <el-input disabled v-model="tranForm.scon_nos" placeholder="请输入销售合同号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="客户订单号" prop="scon_cust_nos">
                <el-input disabled v-model="tranForm.scon_cust_nos" placeholder="请输入客户订单号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="到港日" prop="tran_eta">
                <el-date-picker v-model="tranForm.tran_eta" type="date" placeholder="选择日期"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="付款方式" prop="cust_payway">
                <el-select filterable v-model="tranForm.cust_payway" placeholder="请选择付款方式">
                  <el-option v-for="item in custPaywayList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="价格条款" prop="cust_paylaw">
                <el-select filterable v-model="tranForm.cust_paylaw" placeholder="请选择价格条款">
                  <el-option v-for="item in custPaylawList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :md="8">
              <el-form-item label="发票日期" prop="tran_ivdate">
                <el-date-picker v-model="tranForm.tran_ivdate" type="date" placeholder="选择日期"> </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="运输方式" prop="cust_shipway">
                <el-select filterable v-model="tranForm.cust_shipway" placeholder="请选择运输方式">
                  <el-option v-for="item in custShipwayList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="运费支付方式" prop="cust_shippay">
                <el-select filterable v-model="tranForm.cust_shippay" placeholder="请选择运费支付方式">
                  <el-option v-for="item in custShippayList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="应收日期" prop="tran_ppdate">
                <el-date-picker v-model="tranForm.tran_ppdate" type="date" placeholder="选择日期"> </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="出运金额" prop="tran_prtotal">
                <el-input disabled v-model="tranForm.tran_prtotal" placeholder="暂无出运金额">
                  <template slot="append">美元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="销售币种" prop="cust_currency">
                <el-select filterable v-model="tranForm.cust_currency" placeholder="请选择销售币种">
                  <el-option v-for="item in custCurrencyList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="费用金额" prop="tran_fetotal">
                <el-input disabled v-model="tranForm.tran_fetotal" placeholder="暂无费用金额">
                  <template slot="append">美元</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="人民币金额" prop="tran_rmbtotal">
                <el-input disabled v-model="tranForm.tran_rmbtotal" placeholder="暂无费用金额">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="汇率" prop="tran_usdrate">
                <el-input v-model="tranForm.tran_usdrate" placeholder="暂无汇率"> </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="航名航班信息" prop="tran_flight">
                <el-select v-model="tranForm.tran_flight" clearable filterable placeholder="请选择">
                  <el-option v-for="item in ['CONTAINER NUMBER/SEAL NUMBER', 'FLIGHT NUMBER']" :key="item" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="起运港" prop="tran_sport">
                <el-select filterable v-model="tranForm.tran_sport" placeholder="请选择起运港">
                  <el-option v-for="item in TranSportList" :key="item.id" :label="item.param2" :value="item.param2"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :md="8">
              <el-form-item label="目的港" prop="cust_dport">
                <el-select filterable v-model="tranForm.cust_dport" placeholder="请选择目的港">
                  <el-option v-for="item in custDportList" :key="item.id" :label="item.param2" :value="item.param2"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :md="8">
              <el-form-item label="目的国" prop="cust_dcountry">
                <el-select filterable v-model="tranForm.cust_dcountry" placeholder="请选择目的国">
                  <el-option v-for="item in custDcountryList" :key="item.id" :label="item.param2" :value="item.param2"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="装运日期" prop="tran_spdate">
                <el-date-picker v-model="tranForm.tran_spdate" type="date" placeholder="选择日期"> </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="离港日" prop="tran_dpdate">
                <el-date-picker v-model="tranForm.tran_dpdate" type="date" placeholder="选择日期"> </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="报关日期" prop="tran_cldate">
                <el-date-picker v-model="tranForm.tran_cldate" type="date" placeholder="选择日期"> </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="货代简称" prop="inst_abbr">
                <el-input v-model="tranForm.inst_abbr" placeholder="暂无货代简称">
                  <template slot="append">
                    <el-link type="primary" class="vg_pointer" @click="dialogInstVisible2 = true">选择 </el-link>
                  </template>
                </el-input>
              </el-form-item>
              &lt;!&ndash;
              <el-form-item label="货代简称" prop="inst_id">
                <el-select v-model="tranForm.inst_id" placeholder="请选择货代简称">
                  <el-option v-for="item in custList" :key="item.inst_id" :value="item.inst_id" :label="item.inst_abbr"></el-option>
                </el-select>
              </el-form-item>
              &ndash;&gt;
            </el-col>
            <el-col :md="8">
              <el-form-item label="报关日期" prop="tran_cldate">
                <el-date-picker v-model="tranForm.tran_cldate" type="date" placeholder="选择日期"> </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="放单日期" prop="tran_bldate">
                <el-date-picker v-model="tranForm.tran_bldate" type="date" placeholder="选择日期"> </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="集装箱号" prop="tran_containo">
                <el-input disabled v-model="tranForm.tran_containo" placeholder="请输入集装箱号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="集装箱封号" prop="tran_sealno">
                <el-input disabled v-model="tranForm.tran_sealno" placeholder="请输入集装箱封号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="箱型" prop="box_type">
                <el-input :maxlength="8" v-model="tranForm.box_type" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="关单号" prop="customs_no">
                <el-input
                  maxlength="20"
                  show-word-limit
                  @input="val => (tranForm.customs_no = helper.keepEngNum(val))"
                  v-model="tranForm.customs_no"
                  placeholder="请输入集装箱号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="装箱日期" prop="pack_date">
                <el-date-picker v-model="tranForm.pack_date" value-format="timestamp" type="date" placeholder="选择日期"> </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="订舱编号" prop="booking_space">
                <el-input :maxlength="20" v-model="tranForm.booking_space" placeholder="请输入订舱编号"> </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="vd_dash"></div>
          <el-row>
            <el-col>
              <el-tabs v-model="activeName2" type="border-card">
                <el-tab-pane label="出运明细" name="first" :key="'first'">
                  <ProdDetail
                    :isShow="isShow"
                    :tranForm="tranForm"
                    @prodSelect="prodSelect"
                    @tranProdPriceChange="tranProdPriceChange"
                    @addFlagIdChange="addFlagIdChange"
                    :addFlagId="addFlagId"
                    @confirmProdIdCopy="confirmProdIdCopy"
                    @confirmProdId="confirmProdId"
                  >
                  </ProdDetail>
                </el-tab-pane>
                <el-tab-pane label="报关明细" name="second" :key="'second'">
                  <PartDetail :isShow="isShow" :tranForm="tranForm"></PartDetail>
                </el-tab-pane>
                <el-tab-pane label="商检明细" name="third" :key="'third'">
                  <InspDetail :isShow="isShow" :tranForm="tranForm"></InspDetail>
                </el-tab-pane>
              </el-tabs>
            </el-col>
          </el-row>
          <div class="vd_dash"></div>
          <el-row>
            <el-tabs v-model="activeName" class="vd_eltab vd_buttom" :disabled="true">
              <el-tab-pane label="费用明细" name="first" :key="'first'">
                <CostTranList @totalExpenses="totalExpenses" :tranForm="tranForm" @feesSelect="feesSelect"> </CostTranList>
              </el-tab-pane>
              <el-tab-pane label="条款明细" name="second" :key="'second'">
                <ClauseTranList
                  :isShow="isShow"
                  :tranForm="tranForm"
                  :cptt_bank_list="cptt_bank_list"
                  @tranClauChange="tranClauChange"
                  @tranClauChange2="tranClauChange2"
                  @tranClauChange3="tranClauChange3"
                >
                </ClauseTranList>
              </el-tab-pane>
            </el-tabs>
          </el-row>
          <el-dialog title="部件" :visible.sync="dialogVisible" width="70%">
            <ClauseTranImport :sconId="tranForm.scon_id" :suppId="tranForm.supp_id" @childChanel="childChanel" @childConfirm="childConfirm"></ClauseTranImport>
          </el-dialog>
        </el-form>
      </el-col>
    </el-row>
    <el-row class="vg_mt_16 vd_bortop">
      <el-col>
        <inputUser :isCookie="false" :stffForm="stffForm" ref="userData"></inputUser>
      </el-col>
    </el-row>
    <el-dialog title="机构信息" :visible.sync="dialogInstVisible2" width="70%">
      <ImportInst @importInstChange="importInstChange" />
    </el-dialog>
  </div>
</template>

<script>
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import { get, post } from '@/api/request';
import { optnAPI } from '@api/modules/optn';
import { tranAPI } from '@/api/modules/tran';
import { cpttAPI } from '@api/modules/comptitle';
import { custAPI } from '@/api/modules/cust';
import ImportInst from './Component/ImportInst.vue';
import { useCpttMixin, useDeptMixin, useStffMixin, useSuppMixin } from '@/assets/js/mixin/initMixin';
import CostTranList from './Component/CostTranList.vue';
import ClauseTranList from './Component/ClauseTranList.vue';
import ProdDetail from './Component/ProdDetail.vue';
import PartDetail from './Component/PartDetail.vue';
import InspDetail from './Component/InspDetail.vue';
import ClauseTranImport from './Component/ClauseTranImport.vue';
import helper from '@assets/js/helper';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'tranAddMain',
  computed: {
    helper() {
      return helper;
    }
  },
  mixins: [useSuppMixin, useCpttMixin, useStffMixin, useDeptMixin],
  components: {
    editHeader,
    inputUser,
    CostTranList,
    ClauseTranList,
    ProdDetail,
    PartDetail,
    InspDetail,
    ClauseTranImport,
    ImportInst
  },
  data() {
    return {
      isShow: true,
      activeName: 'first',

      activeName2: 'first',
      btn: { edit: true },
      stffForm: {},
      dialogInstVisible2: false,
      rules: {
        cust_payway: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        cust_paylaw: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        cust_shipway: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        cust_shippay: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        cust_currency: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        tran_sport: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        cust_dport: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        cust_dcountry: [{ required: true, trigger: ['blur', 'change'], message: ' ' }]
      },
      tranForm: {
        tran_id: null,
        cust_id: null, //客户id
        cust_abbr: null, //客户简称
        cptt_aid: null, //公司抬头
        tran_stff_id: null, //出运经办人
        scon_nos: null, //外销合同
        scon_cust_nos: null, //客户订单号
        cust_payway: null, //付款方式
        cust_paylaw: null, //价格条款
        tran_ivdate: null, //发票日期
        cust_shipway: null, //运输方式
        cust_shippay: null, //运费支付方式
        tran_ppdate: null, //应收日期
        tran_prtotal: null, //出运金额
        cust_currency: 'USD', //币种
        tran_fetotal: '0.0000', //费用金额
        tran_rmbtotal: '0.0000', //人民币金额
        tran_usdrate: '6', //汇率
        tran_flight: null, //航名航班信息
        tran_sport: null, //起运港
        cust_dport: null, //目的港
        cust_dcountry: null, //目的国
        tran_spdate: null, //装运日期
        tran_dpdate: null, //离港日
        tran_eta: null, //到港日
        tran_cldate: null, //报关日期
        inst_id: null, //货代简称
        inst_abbr: null, //货代简称
        tran_lading: null, //提单号
        tran_bldate: null, //放单日期
        tran_containo: null, //集装箱号
        tran_sealno: null, //集装箱封号
        inst_bid: null, //通知人id
        inform_name: null, //通知人简称
        cnee_name: null, //收货人
        cnee_phone: null, //收货人联系电话
        inst_baddr: null, //通知人地址
        clau_content: null, //送货资料
        clau_bcontent: null, //船公司联系
        cptt_bank_name: null, //银行名称
        cptt_bank_account: null, //银行信息
        cust_baddr: null, //客户地址
        inst_cid: null, //送货仓库id
        inst_cabbr: null, //送货仓库简称
        inst_ccontact: null, //送货联系人
        inst_ccphone: null, //送货联系人电话
        inst_caddr: null, //送货地址
        cust_shipmark: null, //唛头
        booking_space: null,
        tran_fees_list: [],
        tran_part_list: [],
        tran_prod_list: [],
        tran_insp_lsit: [],
        scon_id: null, //销售合同号
        tran_no1: '', //发票号前10位,不可变
        tran_no2: '', //发票号前4位
        tran_no3: '', //发票号后1位,m
        pack_date: new Date()
      },
      feesKey: 0,
      feesSelectList: [],
      prodSelectList: [],
      dialogVisible: false,
      feesListCopy: [],
      partListCopy: [],
      prodListCopy: [],
      inspListCopy: [],
      stffOptionList: [],
      custCurrencyList: [],
      custPaywayList: [],
      custPaylawList: [],
      custShipwayList: [],
      custShippayList: [],
      TranSportList: [],
      custDportList: [],
      custDcountryList: [],
      cpttList: [],
      custList: [],
      cptt_bank_list: [], //银行
      addFlagId: -1,
      tranFormRules: {
        cptt_id: [{ required: true, message: ' ', trigger: 'blur' }],
        tran_stff_id: [{ required: true, message: ' ', trigger: 'blur' }],
        inst_id: [{ required: true, message: ' ', trigger: 'blur' }],
        inst_bid: [{ required: true, message: ' ', trigger: 'blur' }],
        inst_abbr: [{ required: true, message: ' ', trigger: 'blur' }],
        inst_cid: [{ required: true, message: ' ', trigger: 'blur' }]
      }
    };
  },
  // watch: {
  //     //费用金额 改变计算总金额
  //     "modrForm.modr_fetotal": {
  //         handler(newVal, oldVal) {
  //             this.modrForm.modr_total = this.helper.haveFour((newVal - 0) + (this.modrForm.modr_prtotal - 0))
  //         }
  //     },
  //     //货款金额 改变计算总金额
  //     "modrForm.modr_prtotal": {
  //         handler(newVal, oldVal) {
  //             this.modrForm.modr_total = this.helper.haveFour((newVal - 0) + (this.modrForm.modr_fetotal - 0))
  //         }
  //     }
  // },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.loadingFlag = false;
      this.getTranInfo();
      this.getCptt();
      this.getCust();
      this.getCustCurrency();
      this.getCustPayway();
      this.getCustPaylaw();
      this.getCustShipway();
      this.getCustShippay();
      this.getTranSport();
      this.getCustDport();
      this.getCustDcountry();
      this.getTranInfo();
    },
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },

    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.show = true;
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(err => console.error(err));
    },
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              return false;
            }
          });
        })
        .catch(err => console.error(err));
    },
    saveInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      let tranForm = JSON.parse(JSON.stringify(this.tranForm));
      tranForm.tran_ivdate = this.helper.getNewDate(tranForm.tran_ivdate); //发票日期
      tranForm.tran_ppdate = this.helper.getNewDate(tranForm.tran_ppdate); //报关日期
      tranForm.tran_cldate = this.helper.getNewDate(tranForm.tran_cldate); //应收日期
      tranForm.tran_bldate = this.helper.getNewDate(tranForm.tran_bldate); //放单日期
      tranForm.tran_spdate = this.helper.getNewDate(tranForm.tran_spdate); //离港日期
      tranForm.tran_spdate = this.helper.getNewDate(tranForm.tran_spdate); //装运日期
      tranForm.tran_dpdate = this.helper.getNewDate(tranForm.tran_dpdate); //离港日期
      tranForm.tran_eta = this.helper.getNewDate(tranForm.tran_eta); //到港日期
      tranForm.tran_id = props.form_id;

      if (tranForm.tran_no) {
        let str = tranForm.tran_no1 + '' + tranForm.tran_no2 + tranForm.tran_no3;
        tranForm.tran_no = str;
      } else {
        tranForm.tran_no = null;
      }
      console.log('12321321', tranForm.tran_no);
      //tranForm.personal = this.$cookies.get('userInfo').personal;
      // if (tranForm.tran_ivdate) {
      //     tranForm.tran_ivdate = parseInt(new Date(tranForm.tran_ivdate).getTime() / 1000)
      // }
      // if (tranForm.tran_ppdate) {
      //     tranForm.tran_ppdate = parseInt(new Date(tranForm.tran_ppdate).getTime() / 1000)
      // }
      // if (tranForm.tran_cldate) {
      //     tranForm.tran_cldate = parseInt(new Date(tranForm.tran_cldate).getTime() / 1000)
      // }
      // if (tranForm.tran_bldate) {
      //     tranForm.tran_bldate = parseInt(new Date(tranForm.tran_bldate).getTime() / 1000)
      // }
      // if (tranForm.tran_spdate) {
      //     tranForm.tran_spdate = parseInt(new Date(tranForm.tran_spdate).getTime() / 1000)
      // }
      // if (tranForm.tran_dpdate) {
      //     tranForm.tran_dpdate = parseInt(new Date(tranForm.tran_dpdate).getTime() / 1000)
      // }
      //报关明细
      this.partListCopy = this.partListCopy.filter(item => tranForm.tran_part_list.every(item1 => item.tran_part_id !== item1.tran_part_id));
      for (let i in this.partListCopy) {
        this.partListCopy[i].destroy_flag = 1;
      }
      tranForm.tran_part_list = tranForm.tran_part_list.concat(this.partListCopy);
      //费用明细
      this.feesListCopy = this.feesListCopy.filter(item => tranForm.tran_fees_list.every(item1 => item.tran_fees_id !== item1.tran_fees_id));
      for (let i in this.feesListCopy) {
        this.feesListCopy[i].destroy_flag = 1;
      }
      tranForm.tran_fees_list = tranForm.tran_fees_list.concat(this.feesListCopy);
      //出运明细
      this.prodListCopy = this.prodListCopy.filter(item => tranForm.tran_prod_list.every(item1 => item.tran_prod_id !== item1.tran_prod_id));
      for (let i in this.prodListCopy) {
        this.prodListCopy[i].destroy_flag = 1;
      }
      tranForm.tran_prod_list = tranForm.tran_prod_list.concat(this.prodListCopy);
      //商检明细
      this.inspListCopy = this.inspListCopy.filter(item => tranForm.tran_insp_list.every(item1 => item.tran_insp_id !== item1.tran_insp_id));
      for (let i in this.inspListCopy) {
        this.inspListCopy[i].destroy_flag = 1;
      }
      tranForm.tran_insp_list = tranForm.tran_insp_list.concat(this.inspListCopy);

      post(tranAPI.editTran, tranForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.isShow = true;
            this.initData();
          } else if (res.data.code === 999) {
            this.$message.error(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(err => console.error(err));
    },

    // 获取byid总信息
    getTranInfo() {
      console.log('this.$route.query', this.$route.query);
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      this.tranForm.tran_id = props.form_id;
      get(tranAPI.getTranById, { tran_id: this.tranForm.tran_id })
        .then(res => {
          if (res.data.code === 0) {
            this.tranForm = res.data.data.form;
            this.btn = res.data.data.btn;
            this.feesListCopy = JSON.parse(JSON.stringify(this.tranForm.tran_fees_list));
            this.partListCopy = JSON.parse(JSON.stringify(this.tranForm.tran_part_list));
            this.prodListCopy = JSON.parse(JSON.stringify(this.tranForm.tran_prod_list));
            this.inspListCopy = JSON.parse(JSON.stringify(this.tranForm.tran_insp_list));
            //发票号
            this.$set(this.tranForm, 'tran_no1', res.data.data.form.tran_no.substring(0, 10));
            this.$set(this.tranForm, 'tran_no2', res.data.data.form.tran_no.substring(10, 14));
            this.$set(this.tranForm, 'tran_no3', res.data.data.form.tran_no.substring(14, 15));

            if (this.tranForm.tran_ivdate === 0) {
              this.tranForm.tran_ivdate = new Date().getTime();
            } else {
              this.tranForm.tran_ivdate = Number(this.tranForm.tran_ivdate) * 1000;
            }
            if (this.tranForm.tran_ppdate) {
              this.tranForm.tran_ppdate = Number(this.tranForm.tran_ppdate) * 1000;
            } else {
              this.tranForm.tran_ppdate = null;
            }
            if (this.tranForm.tran_cldate) {
              this.tranForm.tran_cldate = Number(this.tranForm.tran_cldate) * 1000;
            } else {
              this.tranForm.tran_cldate = null;
            }
            if (this.tranForm.tran_bldate) {
              this.tranForm.tran_bldate = Number(this.tranForm.tran_bldate) * 1000;
            } else {
              this.tranForm.tran_bldate = null;
            }
            if (this.tranForm.tran_spdate) {
              this.tranForm.tran_spdate = Number(this.tranForm.tran_spdate) * 1000;
            } else {
              this.tranForm.tran_spdate = null;
            }
            if (this.tranForm.tran_dpdate) {
              this.tranForm.tran_dpdate = Number(this.tranForm.tran_dpdate) * 1000;
            } else {
              this.tranForm.tran_dpdate = null;
            }
            if (this.tranForm.tran_eta) {
              this.tranForm.tran_eta = Number(this.tranForm.tran_eta) * 1000;
            } else {
              this.tranForm.tran_eta = null;
            }
            get(cpttAPI.getCpttById, { scon_id: this.tranForm.cptt_aid })
              .then(res => {
                if (res.data.code === 0) {
                  this.cptt_bank_list = res.data.data.form.cptt_bank_list;
                  return;
                }
                this.$message.error(res.data.code);
              })
              .catch(err => console.error(err));
            // this.stffForm.stff_name = this.tranrForm.stff_name;
            // this.stffForm.dept_name = this.tranrForm.dept_name;
            // this.stffForm.dept_team_name = this.tranrForm.dept_team_name;
            // this.stffForm.user_id = this.tranrForm.user_id;
            // this.stffForm.dept_id = this.tranrForm.dept_id;
            // this.stffForm.stff_id = this.tranrForm.stff_id;
            this.calcForm();
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(err => console.error(err));
    },
    // 计算内容
    calcForm() {
      for (let i = 0; i < this.tranForm.tran_prod_list.length; i++) {
        this.tranForm.tran_prod_list[i].prod_subtotal = this.helper.haveFour(
          (Number(this.tranForm.tran_prod_list[i].prod_usd) * 100000 * Number(this.tranForm.tran_prod_list[i].prod_ship)) / 100000
        );
        this.tranForm.tran_prod_list[i].prod_box_num = parseInt(
          (Number(this.tranForm.tran_prod_list[i].prod_num) * 100000) / Number(this.tranForm.tran_prod_list[i].prod_qpc) / 100000
        );
        this.tranForm.tran_prod_list[i].prod_sum_gweight = this.helper.reservedDigits(
          (Number(this.tranForm.tran_prod_list[i].prod_gweight) * 100000 * Number(this.tranForm.tran_prod_list[i].prod_box_num)) / 100000
        );
        this.tranForm.tran_prod_list[i].prod_sum_nweight = this.helper.reservedDigits(
          (Number(this.tranForm.tran_prod_list[i].prod_nweight) * 100000 * Number(this.tranForm.tran_prod_list[i].prod_box_num)) / 100000
        );
        this.tranForm.tran_prod_list[i].prod_sum_vol = this.helper.reservedDigits(
          (Number(this.tranForm.tran_prod_list[i].prod_vol) * 100000 * Number(this.tranForm.tran_prod_list[i].prod_box_num)) / 100000
        );
        this.tranForm.tran_prod_list[i].prod_usd = this.helper.haveFour(this.tranForm.tran_prod_list[i].prod_usd);
      }
      for (let i = 0; i < this.tranForm.tran_part_list.length; i++) {
        this.tranForm.tran_part_list[i].prod_subtotal = this.helper.haveFour(
          (Number(this.tranForm.tran_part_list[i].prod_dusd) * 100000 * Number(this.tranForm.tran_part_list[i].prod_ship)) / 100000
        );
        this.tranForm.tran_prod_list[i].prod_box_num = parseInt(
          (Number(this.tranForm.tran_part_list[i].prod_num) * 100000) / Number(this.tranForm.tran_part_list[i].prod_qpc) / 100000
        );
        this.tranForm.tran_part_list[i].prod_sum_gweight = this.helper.reservedDigits(
          (Number(this.tranForm.tran_part_list[i].prod_gweight) * 100000 * Number(this.tranForm.tran_part_list[i].prod_box_num)) / 100000
        );
        this.tranForm.tran_part_list[i].prod_sum_nweight = this.helper.reservedDigits(
          (Number(this.tranForm.tran_part_list[i].prod_nweight) * 100000 * Number(this.tranForm.tran_part_list[i].prod_box_num)) / 100000
        );
        this.tranForm.tran_part_list[i].prod_sum_vol = this.helper.reservedDigits(
          (Number(this.tranForm.tran_part_list[i].prod_vol) * 100000 * Number(this.tranForm.tran_part_list[i].prod_box_num)) / 100000
        );
        this.tranForm.tran_part_list[i].prod_dusd = this.helper.haveFour(this.tranForm.tran_part_list[i].prod_dusd);
      }
      for (let i = 0; i < this.tranForm.tran_insp_list.length; i++) {
        this.tranForm.tran_insp_list[i].prod_subtotal = this.helper.haveFour(
          (Number(this.tranForm.tran_insp_list[i].prod_usd) * 100000 * Number(this.tranForm.tran_insp_list[i].prod_ship)) / 100000
        );
        this.tranForm.tran_insp_list[i].prod_box_num = parseInt(
          (Number(this.tranForm.tran_insp_list[i].prod_num) * 100000) / Number(this.tranForm.tran_insp_list[i].prod_qpc) / 100000
        );
        this.tranForm.tran_insp_list[i].prod_sum_gweight = this.helper.reservedDigits(
          (Number(this.tranForm.tran_insp_list[i].prod_gweight) * 100000 * Number(this.tranForm.tran_insp_list[i].prod_box_num)) / 100000
        );
        this.tranForm.tran_insp_list[i].prod_sum_nweight = this.helper.reservedDigits(
          (Number(this.tranForm.tran_insp_list[i].prod_nweight) * 100000 * Number(this.tranForm.tran_insp_list[i].prod_box_num)) / 100000
        );
        this.tranForm.tran_insp_list[i].prod_sum_vol = this.helper.reservedDigits(
          (Number(this.tranForm.tran_insp_list[i].prod_vol) * 100000 * Number(this.tranForm.tran_insp_list[i].prod_box_num)) / 100000
        );
        this.tranForm.tran_insp_list[i].prod_usd = this.helper.haveFour(this.tranForm.tran_insp_list[i].prod_usd);
      }
    },

    // 出运明细选择
    prodSelect(value) {
      let val = JSON.parse(JSON.stringify(value));
      this.prodSelectList = val;
    },
    // 费用选择
    feesSelect(val) {
      this.feesSelectList = JSON.parse(JSON.stringify(val));
    },
    // 新增费用明细
    feesAdd() {
      let item = {
        scon_no: '',
        scon_fees_name: '',
        scon_fees_usd: '',
        scon_fees_invoice: '',
        scon_fees_remark: '',
        key: this.feesKey
      };
      this.tranForm.tran_fees_list.push(item);
      this.feesKey++;
    },
    // 费用删除
    feesDelete() {
      this.tranForm.tran_fees_list = this.tranForm.tran_fees_list.filter(item => this.feesSelectList.every(item1 => item.key !== item1.key));
      for (let i = 0; i < this.tranForm.tran_fees_list.length; i++) {
        this.tranForm.tran_fees_list[i].key = Number(i);
      }
      this.feesKey = this.tranForm.tran_fees_list.length;
    },

    // 关闭弹框
    childChanel() {
      this.dialogVisible = false;
    },
    // 确认传值
    childConfirm(value) {
      let val = JSON.parse(JSON.stringify(value));
      val = val.filter(item => this.tranForm.tran_prod_list.every(item1 => item.tran_prod_id !== item1.tran_prod_id));
      this.tranForm.tran_prod_list = this.tranForm.tran_prod_list.concat(val);
      this.dialogVisible = false;
    },
    //获取出运id值
    // confirmProdId(val) {
    //     this.showFlagNumId = val
    // },
    // confirmProdIdCopy(val) {
    //     this.showFlagNumIdCopy = val
    // },
    // // 计算费用总额
    totalExpenses(val) {
      this.tranForm.tran_fetotal = val;
      this.calcPrice();
    },
    //金额
    tranProdPriceChange() {
      let sum = 0;
      this.tranForm.tran_prod_list.forEach(item => {
        sum += item.prod_subtotal - 0;
      });
      //this.tranForm.scon_prtotal = this.helper.haveFour(sum)
    },
    //客户简称,通知人
    tranClauChange(row, val) {
      if (val !== 2) {
        this.tranForm.cust_bid = row.cust_id;
        this.tranForm.cust_babbr = row.cust_name;
        this.tranForm.inform_name = row.inform_name; //通知人
        this.tranForm.inst_baddr = row.inform_site; //通知人地址
        this.tranForm.cnee_name = row.cnee_name; //收货人
        // this.tranForm.cust_abbr = row.cust_abbr;
        this.tranForm.cust_bcontact = row.cust_contact; //客户联系人
        this.tranForm.cust_bphone = row.cust_phone; //客户联系人电话
        this.tranForm.cust_baddr = row.cust_addr; //客户地址
        get(cpttAPI.getCpttBankByCpttId, { cptt_id: row.cptt_aid })
          .then(res => {
            if (res.data.code === 0) {
              this.cptt_bank_list = res.data.data.list;
              return;
            }
            this.$message.error(res.data.msg);
          })
          .catch(res => {
            this.$message.error(res.data.msg);
          });
      }
    },
    //送货仓库
    tranClauChange2(row) {
      this.tranForm.inst_cid = row.inst_id;
      this.tranForm.inst_cabbr = row.inst_abbr; //送货人简称
      this.tranForm.inst_ccontact = row.inst_contact; //送货人
      this.tranForm.inst_ccphone = row.inst_cphone; //送货人电话
      this.tranForm.inst_caddr = row.inst_addr; //送货人地址
    },
    // //文本信息
    // tranClauChange3(row) {
    //     this.tranForm.clau_content = row.clau_content
    //     //this.tranForm.clau_content=row.clau_bcontent
    // },
    //客户简称,通知人
    tranClauChange3(row, val) {
      if (val === 2) {
        this.tranForm.clau_bcontent = row.clau_content; //船公司联系
      } else {
        this.tranForm.clau_content = row.clau_content; //送货资料
      }
    },

    //货代简称
    importInstChange(row) {
      this.tranForm.inst_abbr = row.inst_abbr;
      this.tranForm.inst_id = row.inst_id;
      this.dialogInstVisible2 = false;
    },

    //外销经办人 改变 外销经办部门
    custStffChange(row) {
      let current = this.stffOptionList.filter(item => item.stff_id === row);
      this.tranForm.cust_dept_id = current[0].dept_id;
    },
    //获取公司抬头
    getCptt() {
      get(cpttAPI.getAllCpttsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.cpttList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(err => console.error(err));
    },
    //获取客户信息
    getCust() {
      get(custAPI.getAllCustsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.custList = res.data.data.list;
            return;
          }
          this.$message.error(res.data.code);
        })
        .catch(err => console.error(err));
    },
    // 获取币种
    getCustCurrency() {
      get(optnAPI.getOptnByPermId, { perm_id: 10015 })
        .then(res => {
          if (res.data.code === 0) {
            this.custCurrencyList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(err => console.error(err));
    },
    //获取付款方式
    getCustPayway() {
      get(optnAPI.getOptnByPermId, { perm_id: 10013 })
        .then(res => {
          if (res.data.code === 0) {
            this.custPaywayList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(err => console.error(err));
    },
    //获取价格条款
    getCustPaylaw() {
      get(optnAPI.getOptnByPermId, { perm_id: 10014 })
        .then(res => {
          if (res.data.code === 0) {
            this.custPaylawList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(err => console.error(err));
    },
    //获取运输方式
    getCustShipway() {
      get(optnAPI.getOptnByPermId, { perm_id: 10017 })
        .then(res => {
          if (res.data.code === 0) {
            this.custShipwayList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(err => console.error(err));
    },
    //获取运费支付方式
    getCustShippay() {
      get(optnAPI.getOptnByPermId, { perm_id: 10020 })
        .then(res => {
          if (res.data.code === 0) {
            this.custShippayList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(err => console.error(err));
    },
    //获取起运港
    getTranSport() {
      get(optnAPI.getOptnByPermId, { perm_id: 10018 })
        .then(res => {
          if (res.data.code === 0) {
            this.TranSportList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //获取目的港
    getCustDport() {
      get(optnAPI.getOptnByPermId, { perm_id: 10018 })
        .then(res => {
          if (res.data.code === 0) {
            this.custDportList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //获取目的国
    getCustDcountry() {
      get(optnAPI.getOptnByPermId, { perm_id: 10003 })
        .then(res => {
          if (res.data.code === 0) {
            this.custDcountryList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },

    // 材料和部件部分
    // 获取公共prod_id
    confirmProdId(val) {
      this.showFlagNumId = val;
    },
    confirmProdIdCopy(val) {
      this.showFlagNumIdCopy = val;
    },
    addFlagIdChange(val) {
      this.addFlagId = val;
    }
  },
  //订单号
  watch: {
    'tranForm.tran_no2': function (newVal, oldVal) {
      if (newVal != newVal.replace(/[^\d]/g, '')) {
        //replace就是清除的意思，判断清除特殊字符串后，如果长度和没有清除之前保持一致
        //那就代表有特殊字符串，就执行这个if代码块
        alert('该区域只能为数字');
        this.tranForm.tran_no2 = '';
      }
    },
    'tranForm.tran_no3': function (newVal, oldVal) {
      if (newVal != newVal.replace(/[^a-z|A-Z]/g, '').toUpperCase()) {
        //replace就是清除的意思，判断清除特殊字符串后，如果长度和没有清除之前保持一致
        //那就代表有特殊字符串，就执行这个if代码块
        alert('该区域只能为大写字母');
        this.tranForm.tran_no3 = '';
      }
    }
  }
};
</script>

<style scoped>
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}

.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 120px;
}

.vd_edhea {
  position: relative;
}

.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #e9e9e9;
  margin: 10px 0;
}
</style>
-->
